import React, { useState } from 'react';
import { Typography, Box, Container, Avatar, Paper, IconButton, Grid, Button } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import useStyles from './styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const TranscriptModal = ({pageContext, language, transcript, handleTranscriptClose, script}) => {

    const theme = useTheme();
    const classes = useStyles();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Modal 
            open={transcript} 
            onClose={handleTranscriptClose}   
            aria-modal="true"
            role="dialog"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: matchesSm ? '100%' : '75%',
                height: matchesSm ? '100%' : undefined,
                bgcolor: 'background.paper',
                borderRadius:matchesSm ? undefined : '40px',
                border: '2px solid white',
                boxShadow: 24,
                p: matchesSm ? 1 : 4,
                outline:'none'
                }}>
                <Box display="flex" justifyContent="space-between">
                <span lang="en"><Typography component="h2" variant={matchesSm ? "bodyheadingsmall" : "bodyheading"} id="modal-modal-title" color="black" p={2}>
                        Video Text Transcript with Description of Visuals
                    </Typography></span>
                    <Box>
                    <IconButton className={classes.iconButton2} tabIndex="0" aria-label='close transcript' component="span" onClick={handleTranscriptClose}>
                        <CloseIcon style={{borderRadius:'50%',cursor: 'pointer', color:'black'}} size="large" />
                    </IconButton>
                    </Box>
                </Box>
                <Paper sx={{ width: '100%', overflow:'hidden'}}>
                <TableContainer sx={{ maxHeight: matchesSm ? 440 : 440,marginTop:'-5px'}}>
                    <Table stickyHeader aria-label="transcript table">
                    <TableHead>
                        <TableRow>
                        {[{ id: 'Audio', label: 'Audio', minWidth: '50%' },{ id: 'visual', label: 'Visual', minWidth:'50%' }].map((column) => (
                            <TableCell
                            
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontStyle:'normal' }}
                            >
                             <span lang="en"><Typography variant="primary">{column.label}</Typography></span>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {script.map((item, index) => (
                            <TableRow hover>
                            
                            <TableCell  key={'audio'+index} align={'left'} style={{overflow:'hidden', fontStyle:'normal', paddingTop:'15px'}}>
                            <span lang="en"><Typography>{item.audio}</Typography></span>
                            </TableCell>
                            <TableCell key={'visual'+index} align={'left'} style={{overflow:'hidden', fontStyle:'normal'}}>
                            <span lang="en"><Typography>{item.visual}</Typography></span>
                            </TableCell>
                        
                            </TableRow>
                        ))} 
                          
                    </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
            </Box>
        </Modal>
)}

export default TranscriptModal;