
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
   btn:{
    borderRadius:35,
    paddingLeft:'25px',
    paddingRight:'25px',
    padding:'15px',
    backgroundColor:'#FFE69D',
    "&:hover":{
      backgroundColor:'#FFCE3B'
    }
   },
   iconButton:{
    width:'70px',
    height:'70px',
      border:'3px solid white'
   },
   iconButton2:{
    width:'50px',
    height:'50px',
    '&:focus': {
      border: '2px solid black', 
    }
   },
    play:{
      backgroundColor:'#000000', 
      opacity:0.6, 
      height: '70px', 
      width: '70px',
      "&:hover": {
        opacity:0.8,
        cursor:'pointer'
      },
    }
});

export default useStyles;