import React, {useState, useEffect} from 'react';
import Layout from '../components/layout';
import LandingHero from '../components/Landingpage/Hero/Hero';
import LanguageContext from "../context/LanguageContext";
import Cards from '../components/Landingpage/Cards/Cards';
import HeroCard from '../components/Landingpage/HeroCard/HeroCard';
import Seo from '../components/seo';
import CircularProgress from '@mui/material/CircularProgress';

const FrontPage = ({pageContext}) => {

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);
  
    if(!hasMounted) 
    return <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}><CircularProgress /></div>

    return (
        <LanguageContext.Consumer>
        {language => (
        <Layout frontpage={pageContext?.frontpage_acf?.heroBackgroundColour}>
             <Seo title={language.english ? "Home" : "Abhaile"} lang={language.english ? "en" : 'ga'} />
                <LandingHero language={language} pageContext={pageContext} />
                <Cards language={language} pageContext={pageContext} />
                <HeroCard language={language} pageContext={pageContext} />
        </Layout>
     )}
     </LanguageContext.Consumer>
    );
}

export default FrontPage;