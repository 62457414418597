import React from 'react';
import { Grid, Box, Typography, Container } from '@mui/material';
import { Link, graphql, StaticQuery } from 'gatsby';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import useStyles from './styles';
import { GatsbyImage, getImage } from "gatsby-plugin-image";



const Cards = ({language, pageContext}) => {

    const classes = useStyles();
      
    return(
    <StaticQuery
      query={graphql`{
        newsCards:allWpNews(limit: 4, sort: {fields: date, order: DESC }){
        nodes{
        title
            date
            news_acf{
              titleIrish
              caption
              captionIrish
              contentirish
              content
              heroBackgroundColour
              heroFontColorBlack
                      heroImage {
                altText
                altTextIrish{
                irishAlt}
                localFile{
                  childImageSharp {
                  gatsbyImageData( 
                    placeholder: BLURRED
            layout: FULL_WIDTH
                  )
                }
                }
               }
             }
           }
         }
      }`}
      render={data => (     
    <Box role="main" aria-label="main">
        <Grid container>
        <Container maxWidth="xl">
            <Grid item xs={12} mb={4}>
              <Grid container>
                <Grid item xs={12} mt={10} mb={3} display="flex" justifyContent="center">
                    <Box>
                      <Link className={classes.cardsLink} to={language.english ? '/case-studies' : '/cas-staideir'} color="inherit" style={{textDecoration: 'none'}}><Typography variant="bodyheading" color="#594E94" component="h2">{language.english ? 'Case Studies' : 'Cás-Staidéir'}</Typography></Link>
                    </Box>
                </Grid>
                {pageContext.frontpage_acf.caseStudies.map((item, index) => {
                    return(
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={2} pl={2} pr={2} pb={4}>
                    <Link to={language.english ? `/case-studies/${item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}` : `/cas-staideir/${item.acf_case_study.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`} color="inherit" style={{textDecoration: 'none'}}>
                        <Card className={classes.card1}>
                        <CardMedia height="140"><GatsbyImage style={{height:'200px'}} imgStyle={{borderRadius:'15px 15px 0px 0px'}} image={getImage(item.acf_case_study?.heroThumbnail?.localFile?.childImageSharp)} /></CardMedia>
                        <Box p={1}>
                        <CardContent>
                            <Typography gutterBottom variant="cardheading2" component="h3">
                            {language.english ? item.title : item.acf_case_study.titleIrish}
                            </Typography>
                            <Typography gutterBottom variant="card3">
                            {language.english ? item.acf_case_study.caption : item.acf_case_study.captionIrish}
                            </Typography>
                        </CardContent>
                        </Box>
                        </Card>
                    </Link>
                    </Grid>
                    )
                })}
            </Grid>
          </Grid>
          </Container>
        </Grid>
        <Grid container style={{backgroundColor:'#E6E4EF'}}>
          <Container maxWidth="xl">
            <Grid item xs={12} mb={4}>
              <Grid container >
                <Grid item xs={12} mt={10} mb={3} display="flex" justifyContent="center">
                    <Box>
                    <Link to={language.english ? '/news' : '/nuacht'} className={classes.cardsLink} color="inherit" style={{textDecoration: 'none'}}><Typography variant="bodyheading" color="#594E94" component="h2">{language.english ? 'Recent News' : 'Nuacht'}</Typography></Link>
                    </Box>
                </Grid>
                {data.newsCards.nodes.map((item, index) => {
                    let date = new Date(item.date).toDateString();
                    let irishDate;
                    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const daysGA = ['Lua', 'Mha', 'Che', 'Dea', 'Aoi', 'Sat', 'Dom'];
                    const monthsGA = ['Ean', 'Fea', 'Mar', 'Aib', 'Bea', 'Mei', 'Lui', 'Lun', 'Mea', 'Dei', 'Sam', 'Nol'];
                    if(!language.english){
                      let day = date.substring(0,3);
                      let month = date.substring(4,7);
                      let newDay;
                      let newMonth;
                      for(let i = 0; i<days.length; i++){
                        if(day === days[i]){
                          newDay = daysGA[i];
                        }
                      }
                      for(let j = 0; j<months.length; j++){
                        if(month === months[j]){
                          newMonth = monthsGA[j];
                        }
                      }
                     
                    let date1 = date.replace(`${day}`, `${newDay}`);
           
                    irishDate = date1.replace(`${month}`, `${newMonth}`);
                
                    }
                    return(
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={2} pl={2} pr={2} pb={4}>
                    <Link to={language.english ? `/news/${item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}` : `/nuacht/${item.news_acf.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`} color="inherit" style={{textDecoration: 'none'}}>
                        <Card className={classes.card2}>
                        <CardMedia height="140"><GatsbyImage style={{height:'200px'}} imgStyle={{borderRadius:'15px 15px 0px 0px'}} image={getImage(item.news_acf?.heroImage?.localFile?.childImageSharp)} /></CardMedia>
                        <Box p={1}>
                        <CardContent>
                            <Typography gutterBottom variant="cardheading2" component="h3">
                            {language.english ? item.title : item.news_acf.titleIrish}
                            </Typography>
                           
                            <Typography variant="card2" color="text.secondary" >{language.english ? date.substring(4,15) : irishDate.substring(4,15)}</Typography>
                        </CardContent>
                        </Box>
                        </Card>
                    </Link>
                    </Grid>
                    )
                })}
            </Grid>
          
          </Grid>
          </Container>
        </Grid>
        </Box>
      )}
    />
    )
}


    

export default Cards;
