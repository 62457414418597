
import { FormatUnderlined, NoEncryption } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cardsLink:{
    '&:hover': {
      textDecoration:'underline!important'
  },
  },
  card1:{
    borderRadius:'15px',
    border:'1px solid #EBE5D0',
    height:'100%', 
    boxShadow: "none", 
    '&:hover': {
        backgroundColor:'#F8F5ED',
        border:'1px solid rgb(206, 191, 137, 1)',
    },
   },
  card2:{
    borderRadius:'15px',
    border:'1px solid #CED0E0',
    height:'100%', 
    boxShadow: "none", 
    '&:hover': {
        cursor:'pointer',
        backgroundColor:'#EEEDF4',
        border:'1px solid rgba(89,78,148,0.6)',
    },
   },
});

export default useStyles;