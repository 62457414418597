import React,{ useState } from 'react';
import { Typography, Box, IconButton, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useStyles from './styles';

const HeroCard = ({pageContext, language}) => {

    const classes = useStyles();

    let arr = pageContext.frontpage_acf.supportItems;

    const [arrNumber, setArrNumber] = useState(0);

    const prevButton = () => {
        if(arrNumber === 0){
            setArrNumber(arr.length-1);
        }else{
            setArrNumber(arrNumber-1);
        }
    }

    const nextButton = () => {
        if(arrNumber === arr.length-1){
            setArrNumber(0);
        }else{
            setArrNumber(arrNumber+1);
        }
    }

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Grid container style={{backgroundColor:'#E6E4EF'}} pb={10} role="complementary" aria-label="complementary">
            <Grid item xs={12} display="flex" justifyContent="center">
            <Box style={{position:'relative',width:'93%',borderRadius:matchesSm ? 20 : 40, }} >        
                <GatsbyImage style={{borderRadius:matchesSm ? 20 : 40, height:matchesLg ? '500px' : '800px', filter: 'grayscale(100%)'}} imgStyle={{maxWidth:'100%'}} image={getImage(pageContext?.frontpage_acf?.supportItemsBackgroundImage?.localFile?.childImageSharp)} alt={language.english ? pageContext?.frontpage_acf?.heroThumbnail?.altText : pageContext?.frontpage_acf?.heroThumbnail?.altTextIrish?.irishAlt} />
                <Box style={{borderRadius:matchesSm ? 20 : 40, position:'absolute', inset:'0 0 0 0', background: 'linear-gradient(180deg, rgba(135, 27, 79, 0.8) 0%, rgba(0, 0, 0, 0) 100%)'}} >

                <Grid container style={{height:matchesSm ? '50%' : matchesLg ? '80%' : '100%'}}>
                    <Grid item xs={12} display="flex" justifyContent="center" style={{ height:matchesLg ? '0px' : undefined}}>
                        <Box style={{ padding: '40px 0', textAlign:'center'}}>
                            <Typography component="h2"  style={{lineHeight: matchesSm ? '32px' : undefined}} fontSize={matchesSm ? '28px' : undefined} variant="heading3" color="white">{language.english ? 'Údarás na Gaeltachta’s support' : 'Tacaíocht Údarás na Gaeltachta'}</Typography>
                        </Box>
                    </Grid>
                        {matchesLg &&
                        <Grid item container xs={12} direction="column" display="flex" justifyContent="flex-end" style={{}}>
                            <Box style={{ height:'50%'}} display="flex">
                                <Grid item xs={2} style={{padding: '150px 0'}} display="flex" justifyContent="center">
                                <IconButton focusVisibleClassName={classes.iconButton} onClick={() => prevButton()} aria-label="previous carousel slide" style={{backgroundColor:'black', height:'40px'}}>
                                    <ArrowBackIcon style={{color:'white'}} />
                                </IconButton>
                                </Grid>
                                <Grid item xs={8} sm={8} style={{}} display="flex" justifyContent="center">
                                    <Box style={{ backgroundColor:'rgba(0, 0, 0, 0.7)', borderRadius:16, padding: '40px 0', height:'265px', width:matchesSm ? '90%' : '70%'}} textAlign="center">
                                        <Box><Typography color="white" variant="heading">{arr[arrNumber].number}</Typography></Box>
                                        <Box pl={4} pr={4}><Typography color="white" variant="cardHero" style={{lineHeight:'32px'}}>{language.english ? arr[arrNumber].description : arr[arrNumber].descriptionirish }</Typography></Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={2} style={{padding: '150px 0'}} display="flex" justifyContent="center">
                                <IconButton focusVisibleClassName={classes.iconButton} onClick={() => nextButton()} aria-label="next carousel slide" style={{backgroundColor:'black', height:'40px'}}>
                                    <ArrowForwardIcon style={{color:'white'}} />
                                </IconButton>
                                </Grid>
                            </Box>
                        </Grid>}

                        {!matchesLg &&
                        <>
                        {arr.map((card,index) => (
                            <Grid key={index} item container xs={3} p={5} direction="column" display="flex" justifyContent="flex-end" >
                                <Box style={{backgroundColor:'rgba(0, 0, 0, 0.7)', height:'300px', width:'102%',borderRadius:16, padding: '48px'}} textAlign="center">
                                    <Box>
                                      
                                        {card.number === "€3.7m" ? 
                                        <span alias="three point seven million euro">
                                            <Typography color="white" variant="heading" fontSize='72px'>{card.number}</Typography>
                                        </span>
                                        :
                                        <Typography color="white" variant="heading" fontSize='72px'>{card.number}</Typography>
                                        }
                                    </Box>
                                    <Box p={2}>
                                        <Typography color="white" variant="cardHero" style={{lineHeight:'32px'}}>{language.english ? card.description : card.descriptionirish}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            ))}
                        </>}
                    </Grid>

                </Box>
            </Box>
             </Grid>
        </Grid>
    )
}

export default HeroCard;