import React, { useState } from 'react';
import { Typography, Box, Container, Avatar, IconButton, Grid, Button } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import useStyles from './styles';

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from 'gatsby';

import TranscriptModal from '../TranscriptModal/Transcript';


const LandingHero = ({pageContext, language}) => {

    const about = () => {
        navigate(language.english ? '/about' : '/fuinn')
    } 

    const theme = useTheme();
    const classes = useStyles();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const color1 = pageContext?.frontpage_acf?.heroBackgroundColour;
    const topColorPercent = matchesSm ? '82%' :'75%';
    const bottomColorPercent = matchesSm ? '18%' :'25%';

    const [popup, setPopup] = useState(false);
    const [transcript, setTranscript] = useState(false);

    function handlePopupOpen(){
        setPopup(true);
    }
    function handleTranscriptOpen(){
        setTranscript(true);
    }
    function handleTranscriptClose(){
        setTranscript(false);
    }
    function handlePopupClose(){
      setPopup(false);
    }

    return (
        <Grid container style={{background: `linear-gradient(to bottom,  ${color1} ${topColorPercent},#FFFFFF ${bottomColorPercent})`}}>
            <Container maxWidth="lg" role="banner" aria-label="hero banner">
                <Grid item xs={12} pl={2} pr={2} pt={4}>
                    <Box style={{position:'relative'}} display="flex" justifyContent="center">
                        <Box style={{borderRadius:40, width:matchesSm ? '100%' : '90%'}}>
                            <Box style={{position:'relative'}}>
                                <GatsbyImage style={{height:matchesSm ? '500px' : undefined,borderRadius: 40}} imgStyle={{maxWidth:'100%', borderRadius: 40}} image={getImage(pageContext?.frontpage_acf?.heroThumbnail?.localFile?.childImageSharp)} alt={language.english ? pageContext?.frontpage_acf?.heroThumbnail?.altText : pageContext?.frontpage_acf?.heroThumbnail?.altTextIrish?.irishAlt} />
                                <Box style={{borderRadius:40, position:'absolute', inset:'0 0 0 0', background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'}} />
                            </Box>
                        </Box>
                        <Box style={{position: 'absolute', right: matchesSm ? '40%' : '48%', left: matchesSm ? '0%' : '0%', bottom: matchesSm ? '5%' : '45%', top: matchesLg ? '6%': '6.5%', width:'100%'}}>
                            <Box pb={matchesSm ? 2 : 6} style={{width:'100%'}} display="flex" justifyContent="center">
                                <Box style={{width:matchesSm ? "65%" : "60%", textAlign:'center'}}>
                                    <Typography variant="heading2" component="h1" lineHeight={matchesSm ? '36px' : undefined}  fontSize={matchesMd ? '28px' : matchesSm ? '28px' : undefined} color="white">{language.english ? pageContext?.frontpage_acf?.heroTitle : pageContext?.frontpage_acf?.heroTitleIrish}</Typography>
                                </Box>
                            </Box>
                            <Box pb={matchesSm ? 1 : 4} style={{width:'100%'}} display="flex" justifyContent="center">
                                <Button id="main" aria-label={language.english ? pageContext?.frontpage_acf?.heroButtonText : pageContext?.frontpage_acf?.heroButtonTextIrish} onClick={() => about()} variant="contained" className={classes.btn} size="small" style={{textTransform:'none', width:matchesSm ? '75%': undefined}}>
                                    <Typography variant="tag" fontSize={matchesMd ? '14px' : matchesSm ? '10px' :  undefined}>{language.english ? pageContext?.frontpage_acf?.heroButtonText : pageContext?.frontpage_acf?.heroButtonTextIrish}</Typography>
                                </Button>
                            </Box>
                            {pageContext?.frontpage_acf?.heroVideo &&
                            <Box style={{width:'100%'}} display="flex" justifyContent="center">
                                <Avatar className={classes.play}>
                                    <IconButton focusVisibleClassName={classes.iconButton} name="play-button" aria-label='play video' onClick={handlePopupOpen}>
                                        <PlayArrowIcon fontSize="large" style={{color:'white'}}/>
                                    </IconButton>
                                </Avatar>
                            </Box>}
                            <Box display="flex" justifyContent="center" pt={matchesSm ? 1.5 : 4}>
                                <IconButton style={{ borderRadius:'32px', padding:'0px'}} focusVisibleClassName={classes.iconButton2} onClick={handleTranscriptOpen}>
                                    <Box className={classes.iconButtonHover} style={{border:'1px solid white', borderRadius:'32px', padding:'16px 24px 16px 24px', background:'rgba(0, 0, 0, 0.5)'}}>
                                        <Typography color="white">{language.english ? 'Read Video Transcript' : 'Léigh tras-scríbhinn físe i mbéarla'}</Typography>
                                    </Box>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
               <TranscriptModal transcript={transcript} handleTranscriptClose={handleTranscriptClose} script={pageContext?.frontpage_acf?.heroVideoTranscript} />
               {pageContext?.frontpage_acf?.heroVideo &&<Modal
                    open={popup}
                    onClose={handlePopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                   <Fade in={popup}>
                        <Box sx={{position: 
                        'absolute',
                        borderRadius:"10px",
                        top: '45%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width:'75%',
                        height: matchesLg ? '40%' : '70%',
                        outline:'none'
                        }}>
                        <Box display="flex" justifyContent="flex-end" pb={2}>
                        <IconButton className={classes.iconButton2} tabIndex="0" aria-label='close video' component="span" onClick={handlePopupClose}>
                            <CloseIcon style={{cursor: 'pointer', color:'white'}} size="large" />
                        </IconButton>
                        </Box>
                        <iframe title="video" aria-label='hero video' src={`${pageContext?.frontpage_acf?.heroVideo}?&autoplay=1`} style={{width:matchesXs ? "102%" : "100%",height:"100%"}} frameborder="0" alt="video" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </Box>
                    </Fade>
                </Modal>}
            </Container>
        </Grid>
)}

export default LandingHero;